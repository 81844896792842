import React from 'react';
import {Router} from 'react-router-dom';

//Ant Design Elements
import Modal from "antd/lib/modal";

import history from './helpers/history';
import api from './helpers/api';
import config from './config';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import { compressToBase64 as lzStringCompress } from 'lz-string';
import { decompressFromBase64 as lzStringDecompress } from 'lz-string';

import Wrapper from './Wrapper';

import 'antd/dist/antd.css';
import ('./styles/App.css' /* webpackChunkName: "App" */);

const cookies = new Cookies();

class App extends React.Component {

    // NOTE: If you change the items defined in the App state, it is best practice
    // to also increment the AppVersion number defined here.
    // When pulling a stored state from a users' cookie, we check against their 
    // version. If missing or less than current, we force them to reload.
    state = {
        /**************************/
        /**/                    /**/
        /**/  AppVersion: 1.2,  /**/
        /**/                    /**/
        /**************************/

        user: {},
        cart: {},
        paidCart: {},
        bookings: {},
        bookingsSDC: {},
        confirmedBookings: {},
        confirmedBookingsSDC: {},
        processingCart: false,
        assignedDriverTrainer: null,
        transmissionType: 'A',
        findAvailability: false,
        totalCartBookingDuration: 0,
        // Facebook
        connectedWithFacebook: false,
        facebookUserId: null,
        // Google
        connectedWithGoogle: false,
        googleUserId: null,
        googleUserAuthId: null,
        // Pickup Address
        pickupAddressChosen: false,
        pickupAddressString: '',
        pickup_address_id: null,
        pickup_address_suburb_id: null,
        pickup_address_suburb_manual_string: null,
        pickup_address_street_number: null,
        pickup_address_street_name: null,
        pickup_address_suburb_name: null,
        pickup_address_state: null,
        pickup_address_postcode: null,
        pickupAddressManualFields: false,
        // Dropoff Address
        displayDropoffAddress: false,
        dropoffAddressChosen: false,
        dropoffAddressString: '',
        dropoff_address_id: null,
        dropoff_address_suburb_id: null,
        dropoff_address_suburb_manual_string: null,
        dropoff_address_street_number: null,
        dropoff_address_street_name: null,
        dropoff_address_suburb_name: null,
        dropoff_address_state: null,
        dropoff_address_postcode: null,
        dropoffAddressManualFields: false,
        loginModalVisible: false,
        loginModalTab: 'register',
        loginModalCallback: null,
        contactModalVisible: false,
        dtModalVisible: false,
        userDrawerVisible: false,
        // Existing User:
        existingUser: false,
        completedLesson: false,
        futureLessons: false,
        firstPurchase: false,
        payByCash: false,
        paidByCash: false,
        prepaidData: {
            available: 0,
            using: 0,
            remaining: 0,
            owing: 0,
            mod1: 0,
            mod2: 0
        },
        // Temporary User Data:
        newGoogleUser: null,
        newFacebookUser: null,
        // Data Validation (database records 'modified' values):
        timestamps: {
            user: null,
            student: null
        },
        prelim: false,

        // Reservation Info
        expiring_notice: null,
        expired_modal_visible: false,
        shortest_reservation: 30,
        sdc_full_modal_visible: false,
        shell_student: false,

        // Voucher Objects
        voucher: {
            package: null,
            hours: 0,
            qty: 1,
            sdc: false,
            form: {
                // Who are we sending it to?
                who: 'to',
                // Delivery Method:
                how: 'email',
                // Personalisation:
                personalise: true,
                occasion: 'None',
                message: null
            }
        },
        cartGV: {},
        paidCartGV: {},
        driverReferralCode: null,
        studentReferralCode: null,
        referringStudentName: null,

        showBanners: false,
        banners: {}
    };

    studentTimeStampCheckerIntervalVar = null;

    reservationCheckIntervalVar = null;

    // Before anything else happens, check cookie for our state object
    constructor(props) {
        super(props);

        /**
         * Load the User State from their previously stored Cookie
         */
        if (typeof(cookies.get(config.environmentShort + 'BS')) !== 'undefined') {
            // Decrypt the stored state and store it in the App state.
            var decryptedStateData = JSON.parse(lzStringDecompress(cookies.get(config.environmentShort + 'BS')));

            // Check for a stored AppVersion. If out of date, force refresh.
            if (typeof(decryptedStateData.AppVersion) === 'undefined' || decryptedStateData.AppVersion !== this.state.AppVersion) {
                console.log('Sorry! There has been an update to our software, and you will need to log in again.');
                // Outdated or non-existant Version. Force refresh.
                cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
                cookies.remove(config.environmentShort + 'BS', {path: '/', domain: config.siteDomain});
                // Reload to start over
                window.setTimeout(()=> {
                    history.go(0);
                }, 1);
            } else {
                // Current Version - set to state and continue to load.
                if (!decryptedStateData.shell_student) {
                    decryptedStateData.loginModalVisible = false;
                }
                this.state = decryptedStateData;
            }
        }

        // If we have a user, start polling for updates from database
        if (!!this.state.user.id) {
            this.startStudentTimeStampChecker();
        }

        ReactGA.initialize(config.googleAnalyticsTrackingKey);
    }

    componentDidUpdate = (prevProps, prevState) => {
        // Send User Data to SmartLook on Production, if it's changed.
        if (prevState.user !== this.state.user && window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
            let userData = {...this.state.user};
            userData['name'] = userData.first_name + ' ' + userData.last_name;
            // eslint-disable-next-line
            /*global smartlook*/ // This line prevents the below throwing an error when not on production
            smartlook('identify', this.state.user?.id, userData);
        }
    };

    // Encrypt the state data and store it in cookie
    storeEncryptedState = (callback) => {
        cookies.set(config.environmentShort + 'BS', lzStringCompress(JSON.stringify(this.state)), {path: '/', domain: config.siteDomain});

        if (typeof callback === 'function') {
            callback();
        }
    }

    sendPageviewToGoogleAnalytics = (page) => {
        ReactGA.pageview(page);
    }

    /**
     * @object data Data Object to Send
     */
    sendEventToGoogleTagManager = (data) => {
        // Send to GTM
        if (typeof(window.dataLayer) !== 'undefined') {
            window.dataLayer.push(data);
        }

        // Send to our own API for Analytics purposes
        api.post("analytics", {
            body: JSON.stringify({
                data: data
            })
        });
    }

    componentDidMount() {
        //Send initial page view to analytics:
        this.sendPageviewToGoogleAnalytics(window.location.pathname + window.location.search);

        // Check for Expired Reservations every 30 seconds.
        if (this.reservationCheckIntervalVar === null) {
            this.reservationCheckIntervalVar = setInterval(() => {
                this.checkReservationExpiry();
            }, 30000);
            // Uncomment the line below, and comment the above line to temporarily keep renewing reservations every 2 minutes to prevent timeouts while developing...
            // @todo: ensure that the above is uncommented and the below is commented prior to go live!!!
            // this.reservationCheckIntervalVar = setInterval(this.renewReservations, 120000);
        }
        this.checkReservationExpiry();

    }

    componentWillUnmount() {
        clearInterval(this.studentTimeStampCheckerIntervalVar);

        clearInterval(this.reservationCheckIntervalVar);
        Modal.destroyAll();
    }


    checkReservationExpiry = () => {
        //Only check if we have at least one booking, and we're not currently processing the cart
        if ((Object.keys(this.state.bookings).length > 0 || Object.keys(this.state.bookingsSDC).length > 0) && !this.state.processingCart) {
            //API Request
            api.post("bookings/checkReservations", {
                body: JSON.stringify({
                    bookings: Object.keys(this.state.bookings),
                    bookingsSDC: Object.values(this.state.bookingsSDC).reduce((acc, elem) => {
                                    acc[elem.module] = elem.id;
                                    return acc;
                                  }, {})
                })
            })
            .then(data => {
                // If an SDC Full notice is received, alert user and remove SDC Bookings from Cart
                if (typeof(data.result) !== 'undefined' && typeof(data.result.sdc_full) !== 'undefined' && data.result.sdc_full) {
                    if (!this.state.sdc_full_modal_visible) {
                        this.setState({
                            sdc_full_modal_visible: true
                        }, () => {
                            Modal.error({
                                title: "Safer Drivers Course Full",
                                content: (
                                    <div>
                                        <p>Sorry, the SDC course you were attempting to book is now full.</p>
                                    </div>
                                ),
                                onOk: async () => {
                                    this.setAppStateValue('bookingsSDC', {}, true, () => {
                                        this.sendEventToGoogleTagManager({
                                            'event': 'cart-removal-sdc'
                                        });

                                        // Remove from Cart
                                        let cart = {...this.state.cart};
                                        delete cart[10];
                                        delete cart[11];
                                        // Update the State
                                        this.setAppStateValue('cart', cart, true, () => {
                                            this.storeEncryptedState();
                                            this.setState({
                                                sdc_full_modal_visible: false
                                            }, () => {
                                                window.location.href = '/book/course/';
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    }
                }

                if (typeof(data.result) !== 'undefined' && typeof(data.result.expiredBookings) !== 'undefined' && data.result.expiredBookings) {
                    if (!this.state.expired_modal_visible) {
                        this.setState({
                            expired_modal_visible: true
                        }, () => {
                            Modal.error({
                                title: "Expired Reservations",
                                content: (
                                    <div>
                                        <p>Sorry, one or more of the bookings you were making has timed out.</p>
                                    </div>
                                ),
                                onOk: async () => {
                                    let bookingObject = {...this.state.bookings};
                                    let originalCount = Object.keys(bookingObject).length;
                                    // Loop through the returned object and remove any expired ones from the bookingObject
                                    for (const [key, value] of Object.entries(data.result.bookings)) {
                                        if (value.status === 'expired') {
                                            delete bookingObject[key];
                                            // Send Cancel Reservation API Request - We don't need to wait for a response - just assume it's ok
                                            await api.post("bookings/cancelReservation", {
                                                body: JSON.stringify({
                                                    booking: key,
                                                    student: true
                                                })
                                            });
                                            this.sendEventToGoogleTagManager({
                                                'event': 'cart-removal-driving-lesson'
                                            });
                                        }
                                    };
                                    let newCount = Object.keys(bookingObject).length;
                                    if (newCount < originalCount) {
                                        // Set into State
                                        this.setAppStateValue('prepaidData', {
                                            available: parseFloat(this.state.prepaidData.available),
                                            using: 0,
                                            remaining: parseFloat(this.state.prepaidData.available),
                                            owing: 0,
                                            mod1: parseInt(this.state.prepaidData.mod1),
                                            mod2: parseInt(this.state.prepaidData.mod2)
                                        });
                                    }
                                    // Set it back into state
                                    this.setAppStateValue('expiring_notice', null, true, () => {
                                        this.setAppStateValue('bookings', (newCount === 0 ? {} : bookingObject), true, () => {
                                            // If it's a new user and they have no bookings, remove the DT so they can see all of them again
                                            if (Object.keys(bookingObject).length === 0 && !this.state.existingUser && !this.state.shell_student) {
                                                this.setAppStateValue('assignedDriverTrainer', null, true);
                                            }
                                            this.setState({
                                                expired_modal_visible: false
                                            }, () => {
                                                // Reload screen here with slight delay to remove 'maximum bookings' warnings or reset an empty cart
                                                if ((originalCount >= config.maximumBookingsPerTransaction && newCount < originalCount) || newCount === 0) {
                                                    this.storeEncryptedState(() => {
                                                        window.setTimeout(()=> {
                                                            history.go(0);
                                                        }, 1);
                                                    });
                                                } else {
                                                    this.storeEncryptedState();
                                                }
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    }
                } else {
                    // Loop through each booking and flag as close to expiring if it is.
                    // URGENT flag (less than 2 minutes)
                    if (typeof(data.result) !== 'undefined' && typeof(data.result.bookings) !== 'undefined') {
                        let urgentCheck = Object.entries(data.result.bookings).some((el) => {
                            return el[1].remaining <= 2;
                        });
                        for (const [, value] of Object.entries(data.result.bookings)) {
                            if (value.status === 'reserved' && value.remaining < 5) {
                                this.setState({
                                    expiring_notice: <span>You have booking reservations which are about to expire. <span className={"renew-reservations-link " + (urgentCheck ? "urgent" : "")} onClick={() => {this.renewReservations();}}>Renew?</span></span>
                                });
                                break;
                            }
                        };
                    }
                    this.setState({
                        shortest_reservation: data.result?.shortest
                    });
                }
            })
            .then(() => {
                this.updateBookingsDuration();
            });
        }
    };

    renewReservations = () => {
        //API Request
        if (Object.keys(this.state.bookings).length > 0) {
            api.post("bookings/extendReservations",{
                body: JSON.stringify({
                    bookings: Object.keys(this.state.bookings)
                })
            })
            .then(() => {
                this.setState({
                    expiring_notice: null
                }, () => {
                    this.checkReservationExpiry();
                });
            });
        }
    }

    startStudentTimeStampChecker = () => {
        if (this.studentTimeStampCheckerIntervalVar === null) {
            this.studentTimeStampCheckerIntervalVar = setInterval(() => {
                this.studentTimeStampQuery();
            }, 120000);
            // Do it once immediately as well, so we don't have to wait until the first interval passes.
            this.studentTimeStampQuery();
        }
    };

    stopStudentTimeStampChecker = () => {
        if (this.studentTimeStampCheckerIntervalVar !== null) {
            clearInterval(this.studentTimeStampCheckerIntervalVar);
            this.studentTimeStampCheckerIntervalVar = null;
        }
    };

    studentTimeStampQuery = () => {
        if (typeof(this.state.user.id) !== 'undefined') {
            api.post("students/refresh",{
                body: JSON.stringify({
                    student_id: this.state.user.id,
                    timestamps: this.state.timestamps
                })
            })
            .then(res => {
                if (typeof(res.result) !== 'undefined' && typeof(res.result.updated) !== 'undefined' && res.result.updated) {
                    // Set all of our App Level properties
                    cookies.set(config.environmentShort + 'AT', res.result.access_token, {path: '/', domain: config.siteDomain});
                    // if Driver Trainer ID has changed, we need to clear their cart
                    let clearCart = false;

                    // User Specific Updates
                    if (res.result.updated_type === 'both' || res.result.updated_type === 'user') {
                        this.setState({
                            pickupAddressString: (typeof(res.result.user.address) === 'undefined' ? this.state.pickupAddressString : res.result.user.address.street_number + " " + res.result.user.address.street_name + ", " + res.result.user.address.suburb.suburb_full_details),
                            pickup_address_id: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_id : res.result.user.address.id),
                            pickup_address_postcode: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_postcode : res.result.user.address.suburb.post_code),
                            pickup_address_state: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_state : res.result.user.address.suburb.states.name),
                            pickup_address_street_name: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_street_name : res.result.user.address.street_name),
                            pickup_address_street_number: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_street_number : res.result.user.address.street_number),
                            pickup_address_suburb_id: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_suburb_id : res.result.user.address.suburb_id),
                            pickup_address_suburb_name: (typeof(res.result.user.address) === 'undefined' ? this.state.pickup_address_suburb_name : res.result.user.address.suburb.suburb_name),
                            completedLesson: ((typeof(res.result.user.completed_lessons) === 'undefined' || res.result.user.completed_lessons === null || parseInt(res.result.user.completed_lessons) === 0) ? false : true),
                            futureLessons: ((typeof(res.result.user.has_future_lessons) === 'undefined' || res.result.user.has_future_lessons === null) ? false : res.result.user.has_future_lessons),
                            user: {
                                id: res.result.user.id,
                                email: res.result.user.email,
                                mobile_phone: res.result.user.mobile_phone,
                                first_name: res.result.user.first_name,
                                last_name: res.result.user.last_name,
                                licence_number: res.result.user.student.licence_number,
                                hours: res.result.user.student.driving_experience,
                                dob: res.result.user.dob,
                                hadSDC: res.result.user.hadSDC,
                                referral_code: res.result.user.referral_code,
                                hide_cash: res.result.user.student.hide_cash_on_website
                            }
                        }, () => {
                            let prepaidAvailable = parseFloat(res.result.user.credits.remaining);
                            let bookingHours = this.countBookingHours();
                            let prepaidUsing = 0;
                            let stillOwing = bookingHours;
                            if (prepaidAvailable < bookingHours) {
                                prepaidUsing = prepaidAvailable;
                                stillOwing = bookingHours - prepaidAvailable;
                            } else {
                                prepaidUsing = bookingHours;
                                stillOwing = 0;
                            }
                            let prepaidRemaining = prepaidAvailable - prepaidUsing;

                            // Set into State
                            this.setState({
                                prepaidData: {
                                    available: prepaidAvailable,
                                    using: prepaidUsing,
                                    remaining: prepaidRemaining,
                                    owing: stillOwing,
                                    mod1: !!res.result.user.credits?.sdc_remaining ? parseInt(res.result.user.credits?.sdc_remaining[10]) : 0,
                                    mod2: !!res.result.user.credits?.sdc_remaining ? parseInt(res.result.user.credits?.sdc_remaining[11]) : 0
                                }
                            });
                        });
                    }
                    // Student Specific Updates
                    if (res.result.updated_type === 'both' || res.result.updated_type === 'student') {
                        if (this.state.assignedDriverTrainer !== null && !!this.state.assignedDriverTrainer.id && this.state.assignedDriverTrainer.id !== null && parseInt(this.state.assignedDriverTrainer.id) !== parseInt(res.result.user.student.driver_trainer_id) && this.state.completedLesson) {
                            clearCart = true;
                        }
                        this.setState({
                            assignedDriverTrainer: {
                                id: (res.result.user.student.driver_trainer_id === null ? (this.state.completedLesson ? null : (this.state.assignedDriverTrainer !== null ? this.state.assignedDriverTrainer.id : null)) : res.result.user.student.driver_trainer_id),
                                display_name: (res.result.user.student.driver_trainer === null ? (this.state.completedLesson ? null : (this.state.assignedDriverTrainer !== null ? this.state.assignedDriverTrainer.display_name : null)) : res.result.user.student.driver_trainer.display_name),
                                headshot: (res.result.user.student.driver_trainer === null ? (this.state.completedLesson ? null : (this.state.assignedDriverTrainer !== null ? this.state.assignedDriverTrainer.headshot : null)) : ((typeof(res.result.user.student?.driver_trainer?.website_headshot) !== 'undefined' && res.result.user.student.driver_trainer?.website_headshot !== null && res.result.user.student.driver_trainer?.website_headshot !== '') ? res.result.user.student.driver_trainer?.website_headshot : (typeof(res.result.user.student.driver_trainer?.website_photo) !== 'undefined' && res.result.user.student.driver_trainer?.website_photo !== null && res.result.user.student.driver_trainer?.website_photo !== '' ? res.result.user.student.driver_trainer?.website_photo : process.env.REACT_APP_SITE_URL + "/img/default-headshot.webp"))), // @todo: remove this fallback to using website_photo when we remove that field in favour of headshots
                                website_bio: (res.result.user.student.driver_trainer === null ? (this.state.completedLesson ? null : (this.state.assignedDriverTrainer !== null ? this.state.assignedDriverTrainer.website_bio : null)) : res.result.user.student.driver_trainer.website_bio),
                                transmissionType: (res.result.user.student.driver_trainer === null ? 'A' : res.result.user.student.driver_trainer.vehicle_transmission)
                            },
                            transmissionType: (res.result.user.student.driver_trainer === null ? 'A' : res.result.user.student.driver_trainer.vehicle_transmission)
                        });
                    }
                    // Generic Updates
                    this.setState({
                        pickupAddressChosen: (typeof(res.result.user.address) === 'undefined' ? false : true),
                        timestamps: {
                            user: res.result.user.modified,
                            student: res.result.user.student.modified
                        }
                    });
                    if (clearCart) {
                        this.setState({
                            cart: {},
                            bookings: {},
                            bookingsSDC: {}
                        }, () => {
                            this.sendEventToGoogleTagManager({
                                'event': 'cart-cleared'
                            });
                            this.storeEncryptedState();
                            history.push('/');
                        });
                    }
                    this.storeEncryptedState();
                }
            });
        }
    };

    countBookingHours = () => {
        let hours = 0;
        Object.values(this.state.bookings).forEach((value) => {
            hours += value.duration;
        });
        return hours;
    };

    loginModalToggle = (tab, callback) => {
        this.setState({
            // Open on the required tab
            loginModalTab: (typeof(tab) === 'undefined' || tab === null) ? 'register' : tab,
            // Show/Hide based on current state.
            loginModalVisible: !this.state.loginModalVisible
        }, () => {
            if (this.state.loginModalVisible) {
                // Store the login Modal Callback function in the state so we can call it after logging in.
                this.setState({
                    loginModalCallback: callback
                });
            } else {
                // Clear the login Modal Callback state
                this.setState({
                    loginModalCallback: null
                });
            }
        });
    };

    contactModalToggle = () => {
        this.setState({
            // Show/Hide based on current state.
            contactModalVisible: !this.state.contactModalVisible
        });
    };

    setAppStateValue = async (key, value, replaceObject = false, callback) => {
        //If value is an object, loop through it, assigning children
        if (typeof value === 'object' && !replaceObject) {
            let cloneState = {...this.state[key]};
            for (var itemKey in value) {
                cloneState[itemKey] = value[itemKey];
            }
            this.setState({
                [key]: cloneState
            }, () => {
                this.storeEncryptedState();
            });
        }
        //Assume we were passed a simple string and assign it.
        else {
            this.setState({
                [key]: value
            }, () => {
                this.storeEncryptedState();
            });
        }
        if (typeof callback === 'function') {
            callback();
        }
    }

    addToCart = (product_id, quantity, name, price, discountCode = false) => {
        // Copy our existing State Cart object
        let cart = {...this.state.cart};

        // Add this Selection to our Cart
        cart[product_id] = {
            quantity: quantity,
            name: name,
            price: price,
            discountCode: discountCode
        };

        // If it's a Lesson Package, Remove all other Lesson Packages
        let lessonPackageProducts = [
            config.products.singleLesson,
            config.products.threeLesson,
            config.products.fourLesson,
            config.products.fiveLesson,
            config.products.sixLesson,
            config.products.eightLesson,
            config.products.tenLesson,
            config.products.twelveLesson,
            config.products.twentyFiveLesson,
            config.products.fortyLesson
        ];
        if (lessonPackageProducts.includes(product_id)) {
            lessonPackageProducts.forEach((lessonPackageProductId) => {
                if (!!cart[lessonPackageProductId] && lessonPackageProductId !== product_id) {
                    delete cart[lessonPackageProductId];
                }
            });
        }

        // Update the State
        this.setState({
            cart: cart
        }, () => {
            this.storeEncryptedState();
        });
    };

    showExistingUserModal = (prelim, same_dt) => {
        let contentToShow = prelim ? (
            <React.Fragment>
                <p className='heading-xs'>Welcome back!</p>
                <p className='paragraph-lg'>Your email address is already registered on our system, please log in before continuing.</p>
                <p className='paragraph-lg'>If you are having trouble booking, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <p className='heading-xs'>Welcome back!</p>
                <p className='paragraph-lg'>A student account already exists with your email address or phone number.</p>
                <p className='paragraph-lg'>To ensure that we re-connect you to your previous driver trainer, and that your personalised experience is guaranteed, please log in before continuing.</p>
                <p className='paragraph-lg'>If you are having trouble booking, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
            </React.Fragment>
        );
        Modal.error({
            icon: false,
            title: false,
            content: contentToShow,
            onOk: (prelim ? this.restartWithPrelimLoginModal : (same_dt ? this.restartWithLoginModal : this.clearEverythingAndStartWithLoginModal))
        });
    };

    clearEverythingAndStartWithLoginModal = () => {
        let previouslyEnteredEmail = {...this.state.user.email};
        let previousShellState = {...this.state}.shell_student;
        // If a Shell Student, remove the "Logged in" student info
        if (this.state.shell_student) {
            cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
            cookies.remove(config.environmentShort + 'BS', {path: '/', domain: config.siteDomain});
            this.stopStudentTimeStampChecker();

            // On production end the smartlook session and start a new one
            if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                // eslint-disable-next-line
                /*global smartlook*/ // This line prevents the below throwing an error when not on production
                smartlook('anonymize');
            }
        }
        // Empty cart / bookings
        this.basicStateReset(() => {
            // Reload window with updated state
            this.setState({
                loginModalVisible: true,
                loginModalTab: 'login',
                user: {
                    email: previouslyEnteredEmail
                },
                shell_student: previousShellState
            }, () => {
                window.scrollTo(0,0);
                this.storeEncryptedState(() => {
                    window.setTimeout(()=> {
                        window.location.reload();
                    }, 1);
                });
            });
        });
    };

    restartWithPrelimLoginModal = () => {
        // Reload window with updated state
        this.setState({
            loginModalVisible: true,
            loginModalTab: 'login',
            prelim: true
        }, () => {
            window.scrollTo(0,0);
            this.storeEncryptedState(() => {
                window.setTimeout(()=> {
                    window.location.reload();
                }, 1);
            });
        });
    };

    restartWithLoginModal = () => {
        // Reload window with updated state
        this.setState({
            loginModalVisible: true,
            loginModalTab: 'login'
        }, () => {
            window.scrollTo(0,0);
            this.storeEncryptedState(() => {
                window.setTimeout(()=> {
                    window.location.reload();
                }, 1);
            });
        });
    };

    clearReservations = () => {
        if (Object.keys(this.state.bookings).length > 0) {
            api.post("bookings/clearReservations", {
                body: JSON.stringify({bookings: Object.keys(this.state.bookings)})
            })
            .then(() => {
                this.updateBookingsDuration();
            });
        }
    };

    addCustomBanner = (type, content, custom_class) => {
        let banners = {...this.state.banners};

        banners[type] = {
            content: content,
            custom_class: custom_class
        };
    
        // Update the State
        this.setState({
            banners: banners,
            showBanners: true
        });
    }

    clearCustomBanner = (type) => {
        let banners = {...this.state.banners};

        //Clear specified banner type (if it exists)
        if (banners[type]) {
            delete banners[type];
        }
    
        // Update the State
        this.setState({
            banners: banners
        });
    }

    basicStateReset = (callback) => {
        // If there was anything in the bookings object, send a request to clear reservations
        this.clearReservations();

        this.setState({
            user: {},
            cart: {},
            bookings: {},
            bookingsSDC: {},
            confirmedBookings: {},
            confirmedBookingsSDC: {},
            assignedDriverTrainer: null,
            processingCart: false,
            transmissionType: 'A',
            findAvailability: false,
            // Facebook
            connectedWithFacebook: false,
            facebookUserId: null,
            // Google
            connectedWithGoogle: false,
            googleUserId: null,
            googleUserAuthId: null,
            // Pickup Address
            pickupAddressChosen: false,
            pickupAddressString: '',
            pickup_address_id: null,
            pickup_address_suburb_id: null,
            pickup_address_street_number: null,
            pickup_address_street_name: null,
            pickup_address_suburb_name: null,
            pickup_address_state: null,
            pickup_address_postcode: null,
            // Dropoff Address
            displayDropoffAddress: false,
            dropoffAddressChosen: false,
            dropoffAddressString: '',
            dropoff_address_id: null,
            dropoff_address_suburb_id: null,
            dropoff_address_street_number: null,
            dropoff_address_street_name: null,
            dropoff_address_suburb_name: null,
            dropoff_address_state: null,
            dropoff_address_postcode: null,
            loginModalVisible: false,
            showPrepaidError: false,
            timestamps: {
                user: null,
                student: null
            },
            prepaidData: {
                available: 0,
                using: 0,
                remaining: 0,
                owing: 0,
                mod1: 0,
                mod2: 0
            }
        }, callback);
    };

    redirect = (url) => {
        history.push(url);
    };

    checkStudentExists = (callback, email, mobile_phone) => {
        let data = {};
        if (!this.state.shell_student && typeof(this.state.user.email) !== 'undefined' && this.state.user.email !== null) {
            data['email'] = this.state.user.email;
        } else if (email !== null) {
            data['email'] = email;
        }
        if (!this.state.shell_student && typeof(this.state.user.mobile_phone) !== 'undefined' && this.state.user.mobile_phone !== null) {
            data['mobile_phone'] = this.state.user.mobile_phone;
        } else if (mobile_phone !== null) {
            data['mobile_phone'] = mobile_phone;
        }
        if (Object.keys(data).length === 0) {
            return null;
        }

        //API Request
        api.post("students/checkStudentExists",{
            body: JSON.stringify(data)
        })
        .then(data => {
            if (data.result.exists) {
                let prelim = false;
                if (data.result.prelim) {
                    prelim = true;
                }

                let same_dt = false;
                if (
                        typeof(data.result.driver_trainer_id) !== 'undefined' && 
                        this.state.assignedDriverTrainer !== null &&
                        parseInt(data.result.driver_trainer_id) === parseInt(this.state.assignedDriverTrainer.id)
                ) {
                    same_dt = true;
                }

                this.showExistingUserModal(prelim, same_dt);
                this.storeEncryptedState();
            } else {
                this.storeEncryptedState();
                if (typeof callback === 'function') {
                    callback();
                }
            }
        });
    };

    // Check Reserved Bookings against logged in Students' existing Bookings
    checkStudentClashes = async(student_id) => {
        return await api.post("students/clashes", {
            body: JSON.stringify({
                'student_id': student_id,
                'bookings': this.state.bookings,
                'bookings_sdc': this.state.bookingsSDC,
            })
        })
        .then(res => {
            //Convert to JSON in case we've received a string response
            if (typeof res === 'string') {
                res = JSON.parse(res);
            }
            //Check for an error response (status of "NOK")
            if (res.status === 'NOK' || res.result.success === false) {
                this.setState({
                    showErrorMessage: true,
                    errorMessage: res.result.error,
                    loading: false
                });
            } else {
                return res.result.unavailable;
            }
            // Return an empty array, just in case.
            return [];
        })
        .catch(error => {
            console.log(error);
        });
    }

    // Make sure that no Bookings or BookingsSDC items in State clash with each other
    checkCartClashes = async(bookingsToCheck) => {
        let clashingBookings = [];

        // Convert to checkable format and loop
        Object.entries(bookingsToCheck).forEach(([check_booking_id, check_booking_data]) => {
            // Check against Bookings
            Object.entries(this.state.bookings).forEach(([against_booking_id, against_booking_data]) => {
                if (check_booking_id === against_booking_id) {
                    return;
                }
                // Check if check_booking_data.start_time BETWEEN against_booking_data.start_time AND against_booking_data.end_time
                if (check_booking_data.start_time.replaceAll(' ','T') > against_booking_data.start_time.replaceAll(' ','T') && check_booking_data.start_time.replaceAll(' ','T') < against_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if check_booking_data.end_time BETWEEN against_booking_data.start_time AND against_booking_data.end_time
                if (check_booking_data.end_time.replaceAll(' ','T') > against_booking_data.start_time.replaceAll(' ','T') && check_booking_data.end_time.replaceAll(' ','T') < against_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if against_booking_data.start_time BETWEEN check_booking_data.start_time AND check_booking_data.end_time
                if (against_booking_data.start_time.replaceAll(' ','T') > check_booking_data.start_time.replaceAll(' ','T') && against_booking_data.start_time.replaceAll(' ','T') < check_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if against_booking_data.end_time BETWEEN check_booking_data.start_time AND check_booking_data.end_time
                if (against_booking_data.end_time.replaceAll(' ','T') > check_booking_data.start_time.replaceAll(' ','T') && against_booking_data.end_time.replaceAll(' ','T') < check_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
            });
            // Check against BookingsSDC
            Object.entries(this.state.bookingsSDC).forEach(([against_booking_id, against_booking_data]) => {
                if (check_booking_id === against_booking_id) {
                    return;
                }
                // Check if check_booking_data.start_time BETWEEN against_booking_data.start_time AND against_booking_data.end_time
                if (check_booking_data.start_time.replaceAll(' ','T') > against_booking_data.start_time.replaceAll(' ','T') && check_booking_data.start_time.replaceAll(' ','T') < against_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if check_booking_data.end_time BETWEEN against_booking_data.start_time AND against_booking_data.end_time
                if (check_booking_data.end_time.replaceAll(' ','T') > against_booking_data.start_time.replaceAll(' ','T') && check_booking_data.end_time.replaceAll(' ','T') < against_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if against_booking_data.start_time BETWEEN check_booking_data.start_time AND check_booking_data.end_time
                if (against_booking_data.start_time.replaceAll(' ','T') > check_booking_data.start_time.replaceAll(' ','T') && against_booking_data.start_time.replaceAll(' ','T') < check_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
                // Check if against_booking_data.end_time BETWEEN check_booking_data.start_time AND check_booking_data.end_time
                if (against_booking_data.end_time.replaceAll(' ','T') > check_booking_data.start_time.replaceAll(' ','T') && against_booking_data.end_time.replaceAll(' ','T') < check_booking_data.end_time.replaceAll(' ','T')) {
                    clashingBookings.push(check_booking_id);
                    return;
                }
            });
        });

        //Return Clashing Bookings from Supplied
        return clashingBookings;
    }

    loginSocialMediaUser = async(res, source, callback) => {
        let previouslyAssignedDT = null;
        if (this.state.shell_student) {
            previouslyAssignedDT = this.state.assignedDriverTrainer.id;
        }

        this.sendPageviewToGoogleAnalytics('/virtual/bookings-new/user-details/login');
        if (source === 'facebook') {
            this.sendEventToGoogleTagManager({
                'event': 'user-login-facebook'
            });
            // Smartlook - Track User Login with Facebook
            if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                // eslint-disable-next-line
                /*global smartlook*/ // This line prevents the below throwing an error when not on production
                smartlook('track', 'user', {
                    "type": "login-facebook"
                });
            }
        } else if (source === 'google') {
            this.sendEventToGoogleTagManager({
                'event': 'user-login-google'
            });
            // Smartlook - Track User Login with Google
            if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                // eslint-disable-next-line
                /*global smartlook*/ // This line prevents the below throwing an error when not on production
                smartlook('track', 'user', {
                    "type": "login-google"
                });
            }
        }

        // Set all of our App Level properties
        if (!this.state.shell_student) {
            this.setAppStateValue('assignedDriverTrainer', {
                id: res.result.user.student.driver_trainer_id,
                display_name: (res.result.user.student.driver_trainer === null ? null : res.result.user.student.driver_trainer.display_name),
                headshot: (res.result.user.student?.driver_trainer === null ? null : ((typeof(res.result.user.student?.driver_trainer?.website_headshot) !== 'undefined' && res.result.user.student?.driver_trainer?.website_headshot !== null && res.result.user.student?.driver_trainer?.website_headshot !== '') ? res.result.user.student?.driver_trainer?.website_headshot : ((typeof(res.result.user.student?.driver_trainer?.website_photo) !== 'undefined' && res.result.user.student?.driver_trainer?.website_photo !== null && res.result.user.student?.driver_trainer?.website_photo !== '') ? res.result.user.student?.driver_trainer?.website_photo : process.env.REACT_APP_SITE_URL + "/img/default-headshot.webp"))), // @todo: remove this fallback to using website_photo when we remove that field in favour of headshots
                website_bio: (res.result.user.student.driver_trainer === null ? null : res.result.user.student.driver_trainer.website_bio),
                transmissionType: (res.result.user.student.driver_trainer === null ? 'A' : res.result.user.student.driver_trainer.vehicle_transmission)
            }, true);
        }
        this.setAppStateValue('timestamps', {
            user: res.result.user.modified,
            student: res.result.user.student.modified
        }, true);
        if (typeof(res.result.user.address) !== 'undefined') {
            this.setAppStateValue('pickupAddressString', res.result.user.address.street_number + " " + res.result.user.address.street_name + ", " + res.result.user.address.suburb.suburb_full_details);
            this.setAppStateValue('pickup_address_id', res.result.user.address.id);
            this.setAppStateValue('pickup_address_postcode', res.result.user.address.suburb.post_code);
            this.setAppStateValue('pickup_address_state', res.result.user.address.suburb.states.name);
            this.setAppStateValue('pickup_address_street_name', res.result.user.address.street_name);
            this.setAppStateValue('pickup_address_street_number', res.result.user.address.street_number);
            this.setAppStateValue('pickup_address_suburb_id', res.result.user.address.suburb_id);
            this.setAppStateValue('pickup_address_suburb_name', res.result.user.address.suburb.suburb_name);
        }
        this.setAppStateValue('transmissionType', (res.result.user.student.driver_trainer === null ? null : res.result.user.student.driver_trainer.vehicle_transmission));
        this.setAppStateValue('existingUser', true);
        this.setAppStateValue('completedLesson', ((typeof(res.result.user.completed_lessons) === 'undefined' || res.result.user.completed_lessons === null || parseInt(res.result.user.completed_lessons) === 0) ? false : true));
        this.setAppStateValue('futureLessons', ((typeof(res.result.user.has_future_lessons) === 'undefined' || res.result.user.has_future_lessons === null) ? false : res.result.user.has_future_lessons));
        this.setAppStateValue('loginModalVisible', false);

        cookies.set(config.environmentShort + 'AT', res.result.access_token, {path: '/', domain: config.siteDomain});
        this.setAppStateValue('prepaidData', {
            available: parseFloat(res.result.user.credits.remaining),
            using: 0,
            remaining: 0,
            owing: 0,
            mod1: !!res.result.user.credits?.sdc_remaining ? parseInt(res.result.user.credits?.sdc_remaining[10]) : 0,
            mod2: !!res.result.user.credits?.sdc_remaining ? parseInt(res.result.user.credits?.sdc_remaining[11]) : 0
        }, true);

        this.setAppStateValue('user', {
            id: res.result.user.id,
            email: res.result.user.email,
            mobile_phone: res.result.user.mobile_phone,
            first_name: res.result.user.first_name,
            last_name: res.result.user.last_name,
            licence_number: res.result.user.student.licence_number,
            hours: res.result.user.student.driving_experience,
            dob: res.result.user.dob,
            hadSDC: res.result.user.hadSDC,
            referral_code: res.result.user.referral_code,
            hide_cash: res.result.user.student.hide_cash_on_website
        }, true, async() => {
            this.setAppStateValue('pickupAddressChosen', (typeof(res.result.user.address) === 'undefined' ? false : true));

            // Clear Cart if Assigned DT Clash:
            let dtClash = false;
            // Check for NO Assigned DT.. that's actually okay, but we'll need to assign THIS DT
            if (this.state.shell_student && res.result.user.student.driver_trainer_id === null) {
                // Assign the selected DT to the Student
                api.post("students/assignDT", {
                    body: JSON.stringify({
                        'student_id': res.result.user.id,
                        'driver_trainer_id': previouslyAssignedDT,
                    })
                })
                .catch(error => {
                    console.log(error);
                });
            } else
            // Check for Assigned DT != Current DT.. Clear Cart and Alert User
            if (this.state.shell_student && parseInt(previouslyAssignedDT) !== parseInt(res.result.user.student.driver_trainer_id)) {
                // Set dtClash = true to throw error later
                dtClash = true;
                this.clearReservations();
                this.setAppStateValue('cart', {}, true);
                this.setAppStateValue('bookings', {}, true);
                this.setAppStateValue('bookingsSDC', {}, true);
                // Force the Assigned DT to the Student's Assigned DT
                this.setAppStateValue('assignedDriverTrainer', {
                    id: res.result.user.student.driver_trainer_id,
                    display_name: (res.result.user.student?.driver_trainer === null ? null : res.result.user.student?.driver_trainer?.display_name),
                    headshot: (typeof(res.result.user.student?.driver_trainer?.website_headshot) !== 'undefined' && res.result.user.student?.driver_trainer?.website_headshot !== null && res.result.user.student?.driver_trainer?.website_headshot !== '') ? res.result.user.student?.driver_trainer?.website_headshot : (typeof(res.result.user.student?.driver_trainer?.website_photo) !== 'undefined' && res.result.user.student?.driver_trainer?.website_photo !== null && res.result.user.student?.driver_trainer?.website_photo !== '' ? res.result.user.student?.driver_trainer?.website_photo : process.env.REACT_APP_SITE_URL + "/img/default-headshot.webp"), // @todo: remove this fallback to using website_photo when we remove that field in favour of headshots
                    website_bio: (res.result.user.student?.driver_trainer === null ? null : res.result.user.student?.driver_trainer?.website_bio),
                    transmissionType: (res.result.user.student?.driver_trainer === null ? 'A' : res.result.user.student?.driver_trainer?.vehicle_transmission)
                }, true);
            }

            // Check for Student Clashes (is logged in Student busy at any of the Reserved times?)
            let studentClash = false;
            if (!dtClash && this.state.shell_student) {
                let clashingBookings = await this.checkStudentClashes(res.result.user.id);
                if (clashingBookings.length > 0) {
                    // Cancel any Unavailable ones, remove from Bookings object
                    let cloneBookings = {...this.state.bookings};

                    // Loop through and Cancel any Reservations that Clash
                    Object.values(clashingBookings).forEach((booking_id) => {
                        if (!!cloneBookings[booking_id]) {
                            // Remove from Bookings object
                            delete cloneBookings[booking_id];
                            // Send Cancel Reservation API Request - We don't need to wait for a response - just assume it's ok
                            api.post("bookings/cancelReservation", {
                                body: JSON.stringify({
                                    booking: booking_id,
                                    student: true
                                })
                            })
                            .then(() => {
                                this.sendEventToGoogleTagManager({
                                    'event': 'cart-removal-driving-lesson'
                                });
                            });
                        }
                    });
                    // Update State
                    this.updateBookingsDuration();
                    this.setAppStateValue('bookings', cloneBookings, true);

                    // Alert Student
                    studentClash = true;
                }
            }

            // Clear Shell Student status, we're now logged in.
            this.setAppStateValue('shell_student', false);

            // Hide Login Modal
            this.setAppStateValue('loginModalVisible', false);

            // Start Student TimeStamp Polling
            this.startStudentTimeStampChecker();

            // Finish Up - Either show an Error, or process the supplied Callback
            if (!studentClash && !dtClash && typeof callback === 'function') {
                callback();
            } else if (dtClash) {
                Modal.error({
                    icon: false,
                    title: false,
                    content: <>
                                <p className='heading-xs'>Welcome back!</p>
                                <p className='paragraph-lg'>Sorry, the reservation(s) you had did not match your Assigned Driver Trainer.</p>
                                <p className='paragraph-lg'>Please try again. The available times shown will now be those of your Driver Trainer.</p>
                                <p className='paragraph-lg'>If you are having trouble booking, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
                            </>,
                });
            } else if (studentClash) {
                Modal.error({
                    icon: false,
                    title: false,
                    content: <>
                                <p className='heading-xs'>Welcome back!</p>
                                <p className='paragraph-lg'>Sorry, some of the Reservations you had in your Cart clashed with other Bookings in your account.</p>
                                <p className='paragraph-lg'>Please try again. The times shown will now be those where both you and your Driver Trainer are available.</p>
                                <p className='paragraph-lg'>If you are having trouble booking, please <a href={config.publicSiteUrl + "/locations-and-contact-us/"} target="_blank" rel="noreferrer">contact our office</a>.</p>
                            </>,
                });
            }
        });
    };

    formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD'
    });

    updateBookingsDuration = () => {
        // Sum our Durations
        let duration = 0;
        Object.keys(this.state.bookings).forEach((key) => {
            duration = duration + this.state.bookings[key].duration;
        });
       
        this.setState({
            totalCartBookingDuration: duration
        });
    };

    renderLoader = () => <div className="loader"></div>;

    render() {
        let content = (
            <Router history={history}>
                <Wrapper
                    {...this.state}
                    addToCart = { this.addToCart }
                    addCustomBanner = { this.addCustomBanner }
                    clearCustomBanner = { this.clearCustomBanner }
                    basicStateReset={this.basicStateReset}
                    checkCartClashes = { this.checkCartClashes }
                    checkStudentClashes = { this.checkStudentClashes }
                    checkStudentExists = { this.checkStudentExists }
                    clearReservations={this.clearReservations}
                    cookies={cookies}
                    formatter = { this.formatter }
                    loginModalToggle = {this.loginModalToggle}
                    contactModalToggle = {this.contactModalToggle}
                    loginSocialMediaUser={this.loginSocialMediaUser}
                    redirect={this.redirect}
                    renderLoader = { this.renderLoader }
                    renewReservations={this.renewReservations}
                    sendEventToGoogleTagManager = { this.sendEventToGoogleTagManager }
                    sendPageviewToGoogleAnalytics = { this.sendPageviewToGoogleAnalytics }
                    setAppStateValue = { this.setAppStateValue }
                    startStudentTimeStampChecker={this.startStudentTimeStampChecker}
                    stopStudentTimeStampChecker={this.stopStudentTimeStampChecker}
                    storeEncryptedState = { this.storeEncryptedState }
                    updateBookingsDuration={this.updateBookingsDuration}
                    countBookingHours={this.countBookingHours}
                />
            </Router>
        );

        return (
            <div className="App">
                {content}
            </div>
        );
    }
};

export default App;
