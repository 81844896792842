import React from 'react';
import ReactDOM from 'react-dom';
import config from '../../../config';
import LoginModal from '../../elements/Students/LoginModal';

class LoginLogout extends React.Component {

    logout = () => {
        this.props.clearCustomBanner('referral');

        return this.props.basicStateReset(() => {
            this.props.cookies.remove(config.environmentShort + 'AT', {path: '/', domain: config.siteDomain});
            this.props.cookies.remove(config.environmentShort + 'BS', {path: '/', domain: config.siteDomain});
            this.props.stopStudentTimeStampChecker();

            // On production end the smartlook session and start a new one
            if (window.location.hostname === 'app.ltrent.com.au' && typeof(smartlook) !== 'undefined' && typeof(smartlook) === 'function') {
                /*global smartlook*/ // This line prevents the below throwing an error when not on production
                smartlook('anonymize');
            }

            window.top.location.href = config.publicSiteUrl;
        });
    }

    render() {
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className="already-student-link">
                    {((!this.props.user.id && this.props.newGoogleUser === null && this.props.newFacebookUser === null) || this.props.shell_student) && (
                        <span onClick={() => this.props.loginModalToggle('login')}>Log In</span>
                    )}
                    {!((!this.props.user.id && this.props.newGoogleUser === null && this.props.newFacebookUser === null) || this.props.shell_student) && (
                        <span onClick={() => this.logout()}>Log Out</span>
                    )}
                </div>
                {(!this.props.user.id || this.props.shell_student) && this.props.loginModalVisible && (
                    <LoginModal
                        {...this.props}
                    />
                )}
            </React.Fragment>,
            document.getElementById(this.props.element),
        );
    }
};

export default LoginLogout;